import { throwError, Observable } from "rxjs";
import { MessageConstants } from "../../constants/message.constants";
import { AppConsts } from "../../constants/system.constants";

export abstract class BaseService {
  constructor() { }

  protected get apiUrl(): string {
    return `${AppConsts.appBaseUrl}`;
  }
  protected get apiDefault(): string {
    return this.apiUrl + "/api";
  }

  protected handleError(errorResponse: any): Observable<any> {
    // Doi voi loi return tu Code
    if (errorResponse?.status === 500) {
      return throwError(MessageConstants.SYSTEM_ERROR_MSG);
    }
    if (errorResponse?.status == 401 || errorResponse?.status == 403 || errorResponse?.status == 502) {
      return throwError(MessageConstants.LOGIN_AGAIN_MSG);
    }
    if (errorResponse?.error?.message) {

      return throwError(
        errorResponse.error.message || MessageConstants.SYSTEM_ERROR_MSG
      );
    }
    return throwError(MessageConstants.SYSTEM_ERROR_MSG);
  }
}
